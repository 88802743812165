import React from 'react'
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'

import { Center } from './layout'

const Footer = ({ basicSettings }) => (
  <FooterDiv>
    <StyledCenter>
      <StaticImage
        src="../images/stamp.png"
        alt="stamp of Simona"
        placeholder="blurred"
        layout="fixed"
        width={50}
        height={50}
      />
      <Border />
      <p>{basicSettings.footerText}</p>
    </StyledCenter>
  </FooterDiv>
)

const FooterDiv = styled.footer`
  height: ${({ theme }) => theme.footer.height};
  padding-top: 30px;
  color: ${({ theme }) => theme.footer.color};
  font-weight: ${({ theme }) => theme.font.weight['300']};
  font-size: ${({ theme }) => theme.font.size.xs};
`

const StyledCenter = styled((props) => <Center {...props} />)`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Border = styled.hr`
  width: 100%;
  margin: 20px 0 30px 0;
  border-top: 1px solid black;
`

export default Footer

import React from 'react'
import { Layout } from '../layout'
import SEO from '../seo'
import { BasicSettings } from '../../types/basicSettings'

interface Props {
  basicSettings: BasicSettings
  children: any
}

export const BasicTemplate = ({ basicSettings, children }: Props) => {
  return (
    <Layout basicSettings={basicSettings}>
      <SEO basicSettings={basicSettings} />
      {children}
    </Layout>
  )
}

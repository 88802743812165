import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import styled from 'styled-components'
import { Center } from './layout'

const Header = ({ basicSettings }) => (
  <HeaderDiv>
    <StyledCenter>
      <H1>
        <Link to={'/'}>{basicSettings.h1Title}</Link>
      </H1>
      <H1Short>
        <Link to={'/'}>{basicSettings.h1TitleShort}</Link>
      </H1Short>

      <div>
        <SocialIcons>
          <a href={basicSettings.twitterUrl} target={'_blank'} rel={'noreferrer'}>
            <StaticImage
              src={'../images/twitter.png'}
              alt={'twitter'}
              height={18}
              placeholder="blurred"
              layout="fixed"
            />
          </a>
          <a href={basicSettings.instaUrl} target={'_blank'} rel={'noreferrer'}>
            <StaticImage
              src={'../images/insta.png'}
              alt={'instagram'}
              height={18}
              placeholder="blurred"
              layout="fixed"
            />
          </a>
        </SocialIcons>
        <TopMenu>
          <li>
            <Link to={'/about'}>About</Link>
          </li>
          <li>
            <Link to={'/gallery'}>Gallery</Link>
          </li>
          <li>
            <Link to={'/contact'}>Contact</Link>
          </li>
        </TopMenu>
      </div>
    </StyledCenter>
  </HeaderDiv>
)

const StyledCenter = styled((props) => <Center {...props} />)`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  height: 100%;
  padding-bottom: 40px !important;
`

const HeaderDiv = styled.header`
  height: ${({ theme }) => theme.header.height};
`

const H1 = styled.h1`
  font-size: ${({ theme }) => theme.font.size.xl};
  font-weight: ${({ theme }) => theme.font.weight['300']};
  color: ${({ theme }) => theme.color.darkGrey};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    display: none;
  }
`

const H1Short = styled(H1)`
  display: none;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    display: block;
  }
`

const SocialIcons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 15px;

  * {
    cursor: pointer;
  }

  > a {
    margin-left: 30px;
  }
`

const TopMenu = styled.ul`
  display: flex;
  font-weight: ${({ theme }) => theme.font.weight['300']};
  color: ${({ theme }) => theme.color.grey};

  li {
    margin-left: 30px;
  }
`

export default Header

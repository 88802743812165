import React from 'react'
import styled from 'styled-components'
import Header from './header'
import Footer from './footer'

import { BasicSettings } from '../types/basicSettings'

interface Props {
  children: any
  basicSettings: BasicSettings
}

export const Layout = ({ children, basicSettings }: Props) => {
  return (
    <>
      <Header basicSettings={basicSettings} />
      <Main>{children}</Main>
      <Footer basicSettings={basicSettings} />
    </>
  )
}

const Main = styled.main`
  display: flex;
  flex-direction: column;
  min-height: calc(100% - ${({ theme }) => theme.header.height} - ${({ theme }) => theme.footer.height});
`

export const Center = styled.div`
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  padding: 0 20px;
`

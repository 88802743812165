import { useStaticQuery, graphql } from 'gatsby'
import { BasicSettings } from '../types/basicSettings'

export const useBasicSettings = (): { basicSettings: BasicSettings } => {
  const data = useStaticQuery(
    graphql`
      {
        markdownRemark(fileAbsolutePath: { regex: "/basic.md/" }) {
          frontmatter {
            siteUrl
            siteTitle
            siteDescription
            h1Title
            h1TitleShort
            twitterAccount
            twitterUrl
            instaUrl
            socialMediaShareImage
            thankyouTitle
            thankyouMessage
            footerText
          }
        }
      }
    `
  )

  return { basicSettings: data.markdownRemark.frontmatter }
}

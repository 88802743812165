import React from 'react'
import Helmet from 'react-helmet'

import { BasicSettings } from '../types/basicSettings'

interface Props {
  pageTitle?: string
  meta?: any
  lang?: string
  description?: string
  basicSettings: BasicSettings
}

function SEO({ pageTitle, description, lang = 'en', meta = [], basicSettings }: Props) {
  const metaTitle = pageTitle ? `${pageTitle} | ${basicSettings.siteTitle}` : basicSettings.siteTitle
  const metaDescription = description || basicSettings.siteDescription
  const socialShareImage = basicSettings.siteUrl + basicSettings.socialMediaShareImage

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={metaTitle}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: metaTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:url`,
          content: basicSettings.siteUrl,
        },
        {
          property: `og:image`,
          content: socialShareImage,
        },

        {
          name: `twitter:title`,
          content: metaTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:creator`,
          content: basicSettings.twitterAccount,
        },
        {
          name: `twitter:url`,
          content: basicSettings.siteUrl,
        },
        {
          name: `twitter:image`,
          content: socialShareImage,
        },
      ].concat(meta)}
    />
  )
}

export default SEO
